import React from 'react';
import PageSection from 'ui-kit/page-section/page-section';
import PageLayout from 'components/layouts/page/page.layout';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { CenteredHero } from 'components/centered-text-hero/centered-hero.component';

const PrescriptionOrdered = ({ data }: { data: GatsbyTypes.PrescriptionOrderedDataQuery }) => {
    const { t } = useTranslation();

    const renderBody = `
        <p>${t('pages.prescriptionOrdered.subtitle.paragraphOne')}</p>
        <p>${t('pages.prescriptionOrdered.subtitle.paragraphTwo')}</p>
    `;

    return (
        <PageLayout metaData={{}}>
            <PageSection className="text-center">
                <CenteredHero
                    backgroundColor="white"
                    eyebrowText=""
                    image={data.easyRefillFlowDesktop}
                    linkTo="/sign-in"
                    linkLabel={t('pages.prescriptionOrdered.buttonLabel')}
                    title={t('pages.prescriptionOrdered.title')}
                    body={renderBody}
                    subtitle=""
                    classNames="centered-hero--full-page flow-states"
                    sectionIndex={0}
                    dataGALocation="PrescriptionOrdered-Signin"
                />
            </PageSection>
        </PageLayout>
    );
};

export default PrescriptionOrdered;

export const query = graphql`
    query PrescriptionOrderedData($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        easyRefillFlowDesktop: file(relativePath: { eq: "assets/images/feather-background-white.jpeg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
